<template>
  <v-container fluid>
    <!-- start of editor -->
    <editor
      :seriesData="seriesArray"
      :branchesData="branchData"
      :defaultSeries="defaultSeries"
      :docCreate="docCreate"
      :docCopyTo="docCopyTo"
      :setup="setup"
      :tableRows="tableRows"
      :initial="dataItem"
      :tableData="tableData"
      :objectName="objectName"
      :activeObject="activeObject"
      :withPayment="withPayment"
      @data="save"
    ></editor>
    <!-- end of editor -->

    <v-row dense>
      <v-col cols="9">
        <!-- <v-btn color="primary" class="right" @click="sendData">Save</v-btn> -->
      </v-col>
    </v-row>
    <!-- snackbar -->
    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
import Editor from "../_components/create-editor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["user"]),
    branchData() {
      return this.$store.state.branches;
    },
  },
  data: () => ({
    dataItem: { parent: false, document_lines: [] },
    docStatus: true,
    docCreate: true,
    docCopyTo: true,
    tenantData: {},
    editMode: false,
    currencies: [],
    setup: {},
    defaultSeries: {},
    seriesArray: [],
    branchesArray: [],
    saleDocs: [],
    objectName: null,
    record: {},
    tableData: [],
    tableRows: [],
    activeObject: null,
    baseObject: null,
    withPayment: null,
  }),
  watch: {
    "$route.params.copyToDoc": {
      handler: "formSettings",
      immediate: true,
    },
    "$route.params.baseObject": {
      handler: "setBaseObject",
      immediate: true,
    },
    "$route.params.docId": {
      handler: "getDocs",
      immediate: true,
    },
  },
  methods: {
    setBaseObject(val) {
      this.baseObject = val;
    },
    save(data) {
      data.ObjType = this.activeObject;

      const formData = new FormData();
      const files = data.oats || [];

      if (files.length > 0) {
        files.forEach((file, f) => {
          const m_file = file.attachmedDetails;
          formData.append("files[" + f + "]", m_file);
        });
      }

      const url = "/inventory-transactions";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            formData.append("ObjType", res.ResponseData.newObjType);
            formData.append("DocEntry", res.ResponseData.id);
            this.uploadAttachments(formData);
          } else {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          console.log(err, "err");
          this.$store.commit("loader", false);
          this.$refs.snackbar.show(err, "red");
        });
    },

    uploadAttachments(formData) {
      const id = this.activeObject;
      const url = "/upload_attachments";
      const self = this;
      this.$store
        .dispatch("post", { url, data: formData })
        .then((res) => {
          if (res.ResultCode == 1200) {
            this.$store.commit("loader", false);
            self.$router.push(`/sales/doc/${id}`);
          } else {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loader", false);
        });
    },
    saveDraft(data) {
      const url = "/drafts";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          console.log(res, "r");
          // self.$refs.snackbar.show("Item group created", "green");
          self.$router.push("/sales/ar-invoice");
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    getCurrencies() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/currency`)
        .then((res) => {
          self.currencies = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    formSettings(objectId) {
      this.activeObject = objectId;
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/form_settings/${objectId}`)
        .then((res) => {
          self.setup = res;
          self.tableRows = res.tabs[0].tableRows;
          self.defaultSeries = res.DfltSeries;
          self.branchesArray = res.branches;
          self.seriesArray = res.Series;

          self.loading = false;
        })
        .catch((err) => {
          console.log(err, "error");
        });

      // set object Name
      this.$store
        .dispatch("get", `/doc_model/${objectId}`)
        .then((res) => {
          self.objectName = res.DocumentName;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
      // end

      if (this.activeObject !== 13) {
        this.withPayment = "N";
      }
      if (this.activeObject == 13) {
        this.withPayment = "Y";
      }
    },
    getDocs(id) {
      var isDoc = this.$route.query.isDoc;
      if (this.baseObject !== null) {
        const objectId = this.baseObject;
        if (id) {
          const self = this;
          this.$store
            .dispatch(
              "get",
              `/inventory-transactions-data/${objectId}/${id}?isDoc=${isDoc}`
            )
            .then((res) => {
              self.tableData = res.document_lines;
              self.dataItem = res;
              self.dataItem.BaseType = objectId;
              self.dataItem.BaseEntry = id;
              self.dataItem.DocNum = null;
              // self.dataItem.CardCode = res.CardCode;
              self.tableData = res.document_lines;
            })
            .catch((err) => {
              this.$refs.snackbar.show(err, "red");
            });
        }
      }
    },
  },
  created() {
    this.getCurrencies();
  },
};
</script>